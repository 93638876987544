import { useState } from 'react';
import { clsx } from 'clsx';
import { getLocalDate } from '@innhold/lib/dates/getLocalDate';
import type { LocalDate } from '@innhold/lib/dates/getLocalDate';
import css from './Lunch.module.css';

const BASE_LUNCH_URL = 'https://api.e24.no/content/v1/comics';
const LUNCH_MAX_DAYS_BACK = 10;

const formatLunchImageDate = (date: LocalDate) => date.format('YYYY-MM-DD');
const getLunchImageUrl = (daysDiff: number) => {
    const date = formatLunchImageDate(getLocalDate().add(daysDiff, 'day'));
    return `${BASE_LUNCH_URL}/${date}`;
};

export const Lunch = () => {
    const [daysBack, setDaysBack] = useState(0);
    const [imageUrl, setImageUrl] = useState(
        () => `${BASE_LUNCH_URL}/${formatLunchImageDate(getLocalDate())}`
    );

    const [canShowPrevious, setCanShowPrevious] = useState(true);
    const [canShowNext, setCanShowNext] = useState(false);
    const [isZoomActive, setIsZoomActive] = useState(false);

    const showPrevious = () => {
        const newDaysBack = daysBack - 1;

        setDaysBack(newDaysBack);
        setImageUrl(getLunchImageUrl(newDaysBack));
        setCanShowPrevious(newDaysBack > -LUNCH_MAX_DAYS_BACK);
        setCanShowNext(true);
    };

    const showNext = () => {
        const newDaysBack = daysBack + 1;

        setDaysBack(newDaysBack);
        setImageUrl(getLunchImageUrl(newDaysBack));
        setCanShowPrevious(true);
        setCanShowNext(newDaysBack < 0);
    };

    return (
        <section className={clsx(css.comic, { [css.zoomActive]: isZoomActive })}>
            <header className={css.comicHeader}>
                <h2>
                    <span>Lunch</span> av Børge Lund
                </h2>
            </header>
            <div className={css.comicImageContainer}>
                <button
                    aria-label="Forrige"
                    className={clsx(css.comicControl, css.comicControlPrev)}
                    disabled={!canShowPrevious}
                    onClick={showPrevious}
                >
                    <span className={css.arrow}>←</span>
                </button>
                <img
                    className={css.comicImage}
                    src={imageUrl}
                    alt=""
                    draggable={false}
                    onClick={() => setIsZoomActive((prevIsActive) => !prevIsActive)}
                />
                <button
                    aria-label="Neste"
                    className={clsx(css.comicControl, css.comicControlNext)}
                    disabled={!canShowNext}
                    onClick={showNext}
                >
                    <span className={css.arrow}>→</span>
                </button>
            </div>
            <div className={css.comicMobileControls}>
                <button
                    className={clsx(css.comicControl, css.comicControlPrev)}
                    disabled={!canShowPrevious}
                    onClick={showPrevious}
                >
                    <span className={css.arrow}>←</span>
                    Forrige
                </button>
                <button
                    className={clsx(css.comicControl, css.comicControlNext)}
                    disabled={!canShowNext}
                    onClick={showNext}
                >
                    Neste
                    <span className={css.arrow}>→</span>
                </button>
            </div>
            <p className={css.comicZoomTip}>
                <span>Tips: Trykk på bildet for å gjøre det større</span>
            </p>
        </section>
    );
};

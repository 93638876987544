import dayjs from 'dayjs';
import type { Dayjs } from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import 'dayjs/locale/nb';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.locale('nb');

type DateLike = Date | string;
export type LocalDate = Dayjs;

export function getLocalDate(sourceDate?: DateLike) {
    return dayjs(sourceDate).tz('Europe/Oslo');
}
